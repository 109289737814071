<div class="tabs-bar">
  <div class="tabs" [ngClass]="{'selected-tab': isTabSelected('Issues')===true}" (click)="changeTab('Issues')">
    <span>Driver Issues</span>
  </div>
  <div class="tabs" [ngClass]="{'selected-tab': isTabSelected('Calendar')===true}" (click)="changeTab('Calendar')">
    <span>Calendar View</span>
  </div>
</div>
<div class="container tms-wrapper" *ngIf="isTabSelected('Issues')">
  <p-dataView #dv [value]="allTicketsBasicData" [paginator]="true" [rows]="limit" [totalRecords]="totalRecords"
    paginatorPosition="bottom" filterBy="brand" [lazy]="true" (onLazyLoad)="searchWithFilters($event)"
    [loading]="!isLoadComplete">
    <p-header>
      <div class="flex-container">
        <div class="ticket-actions">
          <input style="width:135px;" type="text" (input)="searchWithFilters()" pInputText [(ngModel)]="search"
            id="searchInput" [placeholder]="searchPlaceholder" />
        </div>
        <div class="ticket-actions">
          <button *ngIf="showFilter"  pButton style="width:100%;padding: 0px 8px;" class="ui-button-info" icon="pi pi-filter"
                (click)="openCSVDialog()">
              </button>
              <button *ngIf="isFilterApplied" pButton style="width:100%;padding: 0px 8px;" class="ui-button-info filter-btn" icon="pi pi-times"
              (click)="clearFilter()">
            </button>
        </div>
        <div class="ticket-actions">
          <p-multiSelect appendTo="body" [options]="categoryList" [(ngModel)]="selectedCategory"
          (onChange)="onSelectType($event.value)" defaultLabel="Driver Category" optionLabel="label"
          display="chip"></p-multiSelect>
        </div>
        <div class="ticket-actions">
          <p-dropdown [options]="searchKeysList" [style]="pDropdownStyleForSearch" (onChange)="setSearchKeys()" [(ngModel)]="selectedSearchKey" placeholder="Search Key">
          </p-dropdown>
        </div>
        <div class="sort-list-btn" style="width: 135px;" (click)="sortTickets('punch')">
          Punch ID<i class="pi pi-sort-alt" style="position:relative;top:2px;"></i>
        </div>
        <div class="sort-list-btn" (click)="sortTickets('time')">
          Time Created <i class="pi pi-sort-alt" style="position:relative;top:2px;"></i>
        </div>
      </div>
      <div class="flex-container">
       <div class="ticket-stat">
         Open Tickets : {{ticketStats.openTicketsLifetime}}
       </div>
       <div class="ticket-stat">
        Reopen Tickets : {{ticketStats.reopenTicketsLifetime}}
      </div>
      <div class="ticket-stat">
        Ticket Resolved (Today) : {{ticketStats.resolvedTicketsToday}}
      </div>
      <div class="ticket-stat">
        Ticket Raised (Today) : {{ticketStats.openTicketsToday}}
      </div>
      </div>
    </p-header>
    <ng-template let-ticket pTemplate="listItem">
      <div class="ui-g-12" class="clickableDiv align_center"
        [ngStyle]="{'border-left': (ticket.currentStage == 'In-Progress' ? '8px solid yellow' : ticket.currentStage == 'Resolved' ? '8px solid green' : ticket.currentStage == 'Closed' ? '8px solid blue' : ticket.currentStage == 'Discard'  ? '8px solid #939393' : '8px solid red') }"
        (click)="getDetailViewForTicket(ticket.ticketId)">
        <div class="ui-g-2">
          <span><b>{{ ticket.currentStage == 'Ticket Created' ? 'Open' : ticket.currentStage }}</b></span>
        </div>
        <div class="ui-g-2" style="padding-left: 10px;">
          <b>{{ ticket.ticketId }}</b>
        </div>
        <div class="ui-g-2" style="padding-left: 10px;">
          <span [ngClass]="{'diamond-class': ticket.performanceType === 'Diamond', 'gold-class': ticket.performanceType === 'Gold','silver-class': ticket.performanceType === 'Silver','amber-class': ticket.performanceType === 'Amber','bronze-class': ticket.performanceType === 'Bronze'}">
            <span class="icon"></span>
            <span style="font-size: 14px;font-family: Montserrat; font-weight: 600;">{{ ticket.performanceType | titlecase }}</span>
          </span>
        </div>
        <div class="ui-g-3" style="padding-left: 10px;">
          <b>{{ ticket.formData.driverId }} <span
              *ngIf="ticket.formData.driverId && ticket.formData.driverName">,</span> {{ticket.formData.driverName}}</b>
        </div>
        <div class="ui-g-4">
          <span class="ticketImportantDetails"><b>{{ ticket.formData.type }}</b>
          </span>
        </div>
        <div class="ui-g-3" style="text-align: center;">
          <b>{{ ticket.createdAt | amTimeAgo: timezone }}</b>
        </div>
        <div class="ui-g-2">
          <b>{{ticket.hubId? hubMap[ticket.hubId]:''}}</b>
        </div>
        <div class="ui-g-3" style="text-align: center;">
          <b>{{ticket.formData.team}}</b>
        </div>
       
      </div>
    </ng-template>
  </p-dataView>

</div>
<div *ngIf="display" class="modal">
<p-dialog header="Filter Tickets" [(visible)]="display" [modal]="true" [style]="{'width':'40%','padding':'30px'}"
(onHide)="hideDialogBox()"  styleClass="custom_dialog">
  <div style="display:flex; flex-wrap: wrap;">

    <div style="display: flex; margin-bottom: 1rem;">
      <span class="inside-cell">
        <p-dropdown [options]="stagesList" styleClass ="pDropdownStyle"  [(ngModel)]="currentStage"
        placeholder="Select Stage" (onChange)="onValueChange()"></p-dropdown>
  </span>
  <span>
    <p-dropdown [options]="teamsList" styleClass ="pDropdownStyle"  [(ngModel)]="currentTeam"
    placeholder="Team" (onChange)="onValueChange()"></p-dropdown>
  </span>
  </div>
  <div style="display: flex; margin-bottom: 1rem;">
    <span class="inside-cell">
      <p-dropdown [options]="newIssueList" styleClass ="pDropdownStyle" (onChange)="populateIssueTypes()" [(ngModel)]="selectedIssue"
      placeholder="Category"></p-dropdown>
</span>
<span>
  <p-dropdown [options]="typesList" styleClass ="pDropdownStyle" [(ngModel)]="selectedType"
  placeholder="Type"></p-dropdown>
</span>
</div>
<div style="display: flex; margin-bottom: 1rem;">
  <span class="inside-cell">
    <p-dropdown [options]="hubList" styleClass ="pDropdownStyle" (onChange)="onValueChange()"  [(ngModel)]="selectedHub" placeholder="Hub">
    </p-dropdown>
</span>
<span>
  <p-multiSelect appendTo="body" [options]="categoryList" [(ngModel)]="selectedCategory"
  (onChange)="onValueChange()" defaultLabel="Driver Category" optionLabel="label"
  display="chip"></p-multiSelect>
</span>
</div>
    <div style="display: flex; align-items: end;">
    <div class="inside-cell">
      <span>Select Date Range</span>
      <span class="ui-float-label" style="margin-top: 1.5rem;">
        <p-calendar [(ngModel)]="start_date" appendTo="body" (onSelect)="dateSelectClickHandler()" [showIcon]="true" inputId="icon"></p-calendar>
        <label for="float-input">Start Date</label>
      </span>
  </div>
  <div class="inside-cell">
    <span class="ui-float-label">
          <p-calendar [(ngModel)]="end_date" appendTo="body" (onSelect)="dateSelectClickHandler()" [showIcon]="true" inputId="icon"></p-calendar>
    <label for="float-input">End Date</label>
    </span>
</div> 
</div>
<div style="margin-top: 1rem;">
<button [ngClass]="{'disabled': isButtonDisabled}"    (click)="filterCsvData()" class="download_btn" style="margin-right: 20px;">Download Csv</button>
<button [ngClass]="{'disabled': isFilterDisabled}"  (click)="applyFilters()" class="filter_btn">Apply Filters</button>

</div>
<div class="loader" *ngIf="activeLoader">
  <p-progressSpinner styleClass="w-2rem h-2rem" strokeWidth="4"  animationDuration="1s"></p-progressSpinner>
</div>

  </div>

</p-dialog>
</div>

<app-ticket-calendar *ngIf="isTabSelected('Calendar')" [type]="'driverTMS'"></app-ticket-calendar>
