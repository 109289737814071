import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HubListState {

  private hubListSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  hubList$: Observable<any> = this.hubListSubject.asObservable();

  private constants: BehaviorSubject<any> = new BehaviorSubject(null);
  constants$: Observable<any> = this.constants.asObservable();

  constructor() { }

  setHubList(hubList: any) {
    this.hubListSubject.next(hubList);
  }

  setConstants(value: any) {
    this.constants.next(value);
  }
}
