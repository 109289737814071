import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Headers } from './models/api.headers';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { Urls } from './models/api.constants';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private _cookieService: CookieService,
    private _router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(request.headers.keys());
    const country = localStorage.getItem('country');
    const token = this._cookieService.get('accessToken' + country + Urls.COOKIE_SUFFIX);

    // give priority to locale from url
    const paths = location.pathname.split('/');
    const urlLocale = paths.length > 1 ? paths[1] : this.locale;
    const countryCode = urlLocale.split('-').pop() || 'IN';
    let updatedRequest;
    const header: any = {};
    for (const headerKey of request.headers.keys()) {
      header[headerKey] = request.headers.get(headerKey);
    }
    // if (!header['Content-Type']) {
    //   header['Content-Type'] = 'application/json';
    // }

    //const zoneId = this._cookieService.get('selectedZone'); // NOW TAKING FROM LOCAL STORAGE FOR BETTER WAY
    const zoneId = localStorage.getItem('selectedZone') ? localStorage.getItem('selectedZone') : this._cookieService.get('selectedZone');
    const hubList = localStorage.getItem('hubList');
    const hub = this._cookieService.get('selectedHub');
    const ticketHub = localStorage.getItem('ticketHubId');
    const project = this._cookieService.get('selectedProject');
    const ssoId=localStorage.getItem('ssoId')
    // console.log(hub)
    if (hub != String(0)) {
      header.hub = hub;
    }
    else if (ticketHub) {
      header.hub = ticketHub;
    }
    if (project) {
      header.project = project;
    }
      if (hub && hub != '0') {
        header.hub = hub;
      }
      else if (ticketHub) {
        header.hub = ticketHub;
      }
      if (hubList) {
        header.hubList = hubList;
      }
      if(zoneId){
        header.zoneId = zoneId;
      }
      if(ssoId){
        header.ssoId=ssoId
      }
      if (countryCode) {
        header.country = countryCode;
      }

      if (token) {
      header.Authorization = `Bearer ${token}`;
      updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });

    } else {
      const newHeaders: any = {};
      if (countryCode) {
        newHeaders.country = countryCode;
      }
      updatedRequest = request.clone({
        headers: new HttpHeaders(newHeaders)
      });
    }
    return next.handle(updatedRequest).pipe(
      tap(
        event => {
          // logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            formatResponse(event.body);
            if (event.body.code === 401) {
              this._cookieService.deleteAll();
              this._router.navigateByUrl('/');
              return;
            }
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            alert('Some error occured!');
            return null;
          }
        }
      )
    );
  }
}
function formatResponse(responseBody): void {
  if (responseBody.errorCode) {
    responseBody.code = responseBody.errorCode;
  }
  if (responseBody.statusCode) {
    responseBody.code = responseBody.statusCode;
  }
  if (responseBody.response) {
    responseBody.data = responseBody.response;
  }
  if (responseBody.errorMessage) {
    responseBody.message = responseBody.message;
  }
}
